export async function getLocationAndCurrency(request) {
  if (!request || !request.headers) {
    console.error("Invalid request object:", request);
    return { countryCode: 'BD', currencyCode: 'BDT', ipAddress: '' };
  }

  const countryCode = request.headers.get('CF-country') || 'BD';
  
  const currencyCode = countryCode === 'BD' ? 'BDT' : 'USD';

  console.log("Country:", countryCode, "Currency:", currencyCode);

  return { 
    countryCode, 
    currencyCode, 
    ipAddress: request.headers.get('cf-connecting-ip') || '' 
  };
}
